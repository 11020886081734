var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('header-top-dashboard',{attrs:{"neutral":""}}),_c('v-container',{staticClass:"pt-0 px-0 mt-n-chat",attrs:{"fluid":""}},[_c('v-row',{staticClass:"px-1 pt-0"},[_c('v-col',{staticClass:"mt-n16",attrs:{"cols":"12"}},[_c('v-card',{staticClass:"v-card-grey mb-1 px-0 "},[_c('v-card-title',{staticClass:"pt-0 pb-0 mb-0 card-title-bloque"},[_c('div',{staticClass:"w100 d-flex"},[_c('p',{staticClass:"tit_card pt-2"},[_vm._v(" "+_vm._s(_vm.$t('WhatsApp Inbox'))+" "),_c('span',{staticClass:"subtit_card"},[_vm._v(" Conversaciones ")])]),_c('div',{staticClass:"buttons-container d-flex justify-end flex-grow-1 mr-10"},[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"mx-2",attrs:{"fab":"","small":"","color":"white","elevation":"0","to":{ path: '/design/bandeja-entrada-completa' }}},'v-btn',attrs,false),on),[_c('v-icon',{staticClass:"btn-submenu-barra selected",staticStyle:{"color":"#039be5"}},[_vm._v(" far fa-comments ")])],1)]}}])},[_c('span',[_vm._v("Chats")])]),_c('v-tooltip',{staticClass:"custom-tooltip",attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"mx-2",attrs:{"fab":"","small":"","color":"white","elevation":"0","to":{ path: '/design/whatsapp-contactos' }}},'v-btn',attrs,false),on),[_c('v-icon',{staticClass:"btn-submenu-barra",staticStyle:{"color":"#607d8b !important"}},[_vm._v(" fas fa-id-badge ")])],1)]}}])},[_c('span',[_vm._v(_vm._s(_vm.$t('Contactos WhatsApp')))])]),_c('v-tooltip',{staticClass:"custom-tooltip",attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"mx-2",attrs:{"fab":"","small":"","color":"white","elevation":"0","to":{ path: '/design/whatsapp-reportes' }}},'v-btn',attrs,false),on),[_c('v-icon',{staticClass:"btn-submenu-barra",staticStyle:{"color":"#607d8b !important"}},[_vm._v(" fas fa-chart-bar ")])],1)]}}])},[_c('span',[_vm._v("Reportes")])]),_c('v-tooltip',{staticClass:"custom-tooltip",attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"mx-2",attrs:{"fab":"","small":"","color":"white","elevation":"0","to":{ path: '/design/whatsapp-actividad' }}},'v-btn',attrs,false),on),[_c('v-icon',{staticClass:"btn-submenu-barra",staticStyle:{"color":"#607d8b !important"}},[_vm._v(" fas fa-list ")])],1)]}}])},[_c('span',[_vm._v("Actividad")])]),_c('v-tooltip',{staticClass:"custom-tooltip",attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"mx-2",attrs:{"fab":"","small":"","color":"white","elevation":"0","to":{ path: '/design/whatsapp-configuracion-bandeja-respuestas' }}},'v-btn',attrs,false),on),[_c('v-icon',{staticClass:"btn-submenu-barra",staticStyle:{"color":"#607d8b !important"}},[_vm._v(" fas fa-cogs ")])],1)]}}])},[_c('span',[_vm._v("Configuracion bandeja de entrada")])])],1)])]),_c('v-card-text',{staticClass:"px-0 py-0 my-0",staticStyle:{"min-height":"700px"}},[_c('chat-window',{attrs:{"current-user-id":_vm.currentUserId,"rooms":_vm.rooms,"messages":_vm.messages,"room-actions":_vm.roomActions,"capture-files":'yes',"loading-rooms":false,"theme":'light',"rooms-loaded":_vm.roomsLoaded,"messages-loaded":_vm.messagesLoaded},on:{"fetch-messages":_vm.loadMessages,"send-message":_vm.sendMessage,"filter-chats":_vm.filterChats}})],1)],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }