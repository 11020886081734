import ChatWindow from '@/components/vue-chat/lib/ChatWindow.vue'
import HeaderTopDashboard from '@/components/Layout/HeaderTopDashboard.vue'
const { io } = require('socket.io-client')

export default {
  name: 'ChatPage',
  components: {
    HeaderTopDashboard,
    ChatWindow,
  },
  data: function () {
    return {
      selectedItem: null,
      selectItems: [
        {
          text: 'NRS-GROUP',
          value: 1,
          avatar: 'https://www.nrs-group.com/assets/img/logo.svg',
        },
        {
          text: 'TROPITOP',
          value: 2,
          avatar: 'https://tropitop.com/img/famiorganichome1-logo-1644394606.jpg',
        },
        {
          text: 'WAUSMS',
          value: 3,
          avatar: 'https://www.360nrs.com/assets/img/favicons/apple-icon.png',
        },
      ],
      socket: null,
      roomsLoaded: false,
      messagesLoaded: false,
      currentUserId: '1234',
      rooms: [],
      messages: [],
      roomActions: [
        { name: 'inviteUser', title: 'Invite User' },
        { name: 'removeUser', title: 'Remove User' },
        { name: 'deleteRoom', title: 'Delete Room' },
      ],
    }
  },
  computed: {

  },
  mounted () {
    const user = JSON.parse(localStorage.getItem('user'))
    this.socket = io('ws://localhost:8083', {
      transports: ['websocket'],
      auth: {
        token: user.token,
      },
    })

    this.socket.on('message', (message) => {
      console.log(message)
      this.messages.push(
        {
          _id: '' + this.randomNumber(),
          indexId: this.randomNumber(),
          content: message,
          senderId: '4321',
          username: 'John snow',
          avatar: 'https://picsum.photos/200',
          date: '13 November',
          timestamp: '10:10',
          system: false,
          saved: true,
          distributed: true,
          seen: true,
          deleted: false,
          failure: true,
          disableActions: false,
          disableReactions: false,
        })
    })

    this.socket.on('connect', () => {
      // update socker id bd
      console.log(this.socket.id)
    })

    this.socket.on('disconnect', () => {
      console.log('disconnected')
      // update socket id = null
    })

    this.rooms = this.getRooms()
 },
  methods: {
    filterChats (filters) {
      const allRooms = this.getRooms()

      if (filters.opened === false && filters.closed === false && filters.pending === false) {
        this.rooms = allRooms
        return
      }
      this.rooms = allRooms.filter(room => room.status.opened === filters.opened && room.status.closed === filters.closed && room.status.pending === filters.pending)
    },
    randomNumber () {
      return Math.floor(Math.random() * 1000)
    },
    loadMessages (data) {
      this.loadMockMessages()
    },
    sendMessage (data) {
      console.log(data)

      this.messages.push({
        _id: '' + this.randomNumber(),
        indexId: 120989,
        content: data.content,
        senderId: '1234',
        username: 'John snow',
        avatar: 'https://picsum.photos/200',
        date: '13 November',
        timestamp: '11:30',
        system: false,
        saved: true,
        distributed: true,
        seen: true,
        deleted: false,
        failure: false,
        disableActions: false,
        disableReactions: false,
      })

      this.socket.emit('message', data.content, (response) => {
        console.log(response)
      })
    },
    getRooms () {
      return [{
          roomId: '1',
          roomName: 'Julian',
          avatar: 'https://picsum.photos/200',
          // unreadCount: 4,
          index: 3,
          status: {
            opened: true,
            closed: false,
            pending: false,
          },
          lastMessage: {
            _id: 'xyz',
            content: 'texto del último mensaje',
            senderId: '1234',
            username: 'John Doe',
            timestamp: '10:20',
            saved: true,
            distributed: false,
            seen: false,
            new: true,
          },
          users: [
            {
              _id: '1234',
              username: 'John Doe',
              avatar: 'https://picsum.photos/200',
              status: {
                state: 'online',
                lastChanged: 'today, 14:30',
              },
            },
            {
              _id: '4321',
              username: 'John Snow',
              avatar: 'https://picsum.photos/200',
              status: {
                state: 'online',
                lastChanged: '14 July, 20:00',
              },
            },
          ],
          typingUsers: [4321],
        },
        {
          roomId: '2',
          roomName: 'Andrea',
          avatar: 'https://picsum.photos/200',
          // unreadCount: 4,
          index: 4,
          status: {
            opened: false,
            closed: true,
            pending: false,
          },
          lastMessage: {
            _id: 'xyz',
            content: 'texto del último mensaje',
            senderId: '1234',
            username: 'John Doe',
            timestamp: '10:20',
            saved: true,
            distributed: false,
            seen: false,
            new: true,
          },
          users: [
            {
              _id: '1234',
              username: 'John Doe',
              avatar: 'https://picsum.photos/200',
              status: {
                state: 'online',
                lastChanged: 'today, 14:30',
              },
            },
            {
              _id: '4321',
              username: 'John Snow',
              avatar: 'https://picsum.photos/200',
              status: {
                state: 'online',
                lastChanged: '14 July, 20:00',
              },
            },
          ],
          typingUsers: [4321],
        },
        {
          roomId: '3',
          roomName: 'Juan',
          avatar: 'https://picsum.photos/200',
          // unreadCount: 4,
          index: 1,
          status: {
            opened: false,
            closed: false,
            pending: true,
          },
          lastMessage: {
            _id: 'xyz',
            content: 'texto del último mensaje',
            senderId: '1234',
            username: 'John Doe',
            timestamp: '10:20',
            saved: true,
            distributed: false,
            seen: false,
            new: true,
          },
          users: [
            {
              _id: '1234',
              username: 'John Doe',
              avatar: 'https://picsum.photos/200',
              status: {
                state: 'online',
                lastChanged: 'today, 14:30',
              },
            },
            {
              _id: '4321',
              username: 'John Snow',
              avatar: 'https://picsum.photos/200',
              status: {
                state: 'online',
                lastChanged: '14 July, 20:00',
              },
            },
          ],
          typingUsers: [4321],
        },
      ]
    },
    loadMockMessages () {
      this.roomsLoaded = false
      this.messagesLoaded = false
      setTimeout(() => {
        this.messages = [
          {
            _id: '' + this.randomNumber(),
            indexId: this.randomNumber(),
            content: 'Message 1',
            senderId: '4321',
            username: 'John snow',
            avatar: 'https://picsum.photos/200',
            date: '13 November',
            timestamp: '10:10',
            system: false,
            saved: true,
            distributed: true,
            seen: true,
            deleted: false,
            failure: true,
            disableActions: false,
            disableReactions: false,
          },
          {
            _id: '' + this.randomNumber(),
            indexId: this.randomNumber(),
            content: 'Message 2',
            senderId: '4321',
            username: 'John snow',
            avatar: 'https://picsum.photos/200',
            date: '13 November',
            timestamp: '10:12',
            system: false,
            saved: true,
            distributed: true,
            seen: true,
            deleted: false,
            failure: false,
            disableActions: false,
            disableReactions: false,
          },
          {
            _id: '' + this.randomNumber(),
            indexId: this.randomNumber(),
            content: 'Message 2',
            senderId: '1234',
            username: 'John Doe',
            avatar: 'https://picsum.photos/200',
            date: '13 November',
            timestamp: '10:20',
            system: false,
            saved: true,
            distributed: true,
            seen: true,
            deleted: false,
            failure: false,
            disableActions: false,
            disableReactions: false,
            files: [
              {
                name: 'My File',
                size: 67351,
                type: 'png',
                audio: true,
                duration: 14.4,
                url: 'https://picsum.photos/200',
                preview: 'https://picsum.photos/200',
                progress: 88,
              },
            ],
            reactions: {
              '😁': [
                '1234', // USER_ID
                '4321',
              ],
              '🥰': [
                '1234',
              ],
            },
            replyMessage: {
              content: 'Reply Message',
              senderId: '4321',
              files: [
                {
                  name: 'My Replied File',
                  size: 67351,
                  type: 'png',
                  audio: true,
                  duration: 14.4,
                  url: 'https://picsum.photos/200',
                  preview: 'https://picsum.photos/200',
                },
              ],
            },
          },
        ]
  
        this.roomsLoaded = true
        this.messagesLoaded = true
      }, 300)
    }
  },
  created () {
    this.selectedItem = this.selectItems[0]
  },
}
